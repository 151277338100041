import React, { useEffect, useState } from 'react'
import { LeaveMSfeatureData } from './data'
import Navbar from '../Navbar/Navbar'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import ProductPage from './ProductPage/ProductPage'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from '../Navbar/Megamenu';

const LeaveManagementSystem = () => {

    const [leavemsfeaturedata, setLeavemsfeatureData] = useState([])

    useEffect(() => {
        document.title = "Leave Management System";
        setLeavemsfeatureData(LeaveMSfeatureData)
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/leave-management-system"
                metaTitle="Sharepoint Leave Management System"
                metaDescription="SharepointEmpower's Leave Management System simplifies leave requests, approvals, and tracking through an intuitive interface. Tailored to your organization's policies, it delivers real-time updates, promoting transparent decision-making. With our system, administrative tasks are streamlined, fostering a productive work environment. Embrace effortless leave management, empowering your team to focus on productivity and achievement. Choose efficiency; choose SharepointEmpower for effortless and informed leave management."
                metaKeywords="leave management system, sharepoint leave management system, microsoft 365 leave management system, m365 leave management system, microsoft leave management system, leave management software, employee leave management system, best leave management software, annual leave software, annual leave management system, hr leave management system, online leave management system, leave application system, hr software leave management, leave software, leave tracking software, leave management app, employee leave software, leave management application, software for leave management system, leave request software, online leave management, online employee leave management system, leave management system for employee, open source leave management, employee leave tracking system, leave management system demo, leave management solution, leave management system dashboard"
            />
            <Magamenu />
            <LazyLoadComponent>
                <ProductPage project="Leave Management System" />
            </LazyLoadComponent>
        </div>
    )
}

export default LeaveManagementSystem