import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InnerPageBanner from '../../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'
import SmallFooterBanner from '../../CommonComponents/BackgroundBanners/SmallFooterBanner/SmallFooterBanner'
import CollaborativePartners from '../../CommonComponents/CollaborativePartners/CollaborativePartners'
import ContentCards from '../../CommonComponents/ContentCards/ContentCards'
import PageHeadingContent from '../../CommonComponents/PageHeadingContent/PageHeadingContent'
import { CardContentData, ServiceInnerContentData } from './ServiceSectionContent'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag'
import Magamenu from '../../Navbar/Megamenu'

const SoftwareDevelopmentService = (props) => {

  const navigate = useNavigate()
  const [servicecontentdata, setServiceContentData] = useState([])
  const [cardcontentdata, setCardContentData] = useState([])

  useEffect(() => {
    document.title = "Software development services company";
    setServiceContentData(ServiceInnerContentData)
    setCardContentData(CardContentData)
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/software-development-services"
        metaTitle="Software development services company"
        metaDescription="Transform your vision into reality with our software development services company. Leverage our expertise in custom software development and innovative solutions. Partner with us to bring your ideas to life and drive your business forward."
        metaKeywords="custom software development company, software development services, custom software development services, custom software company, software development services company, "
      />

      <Magamenu />
      {
        servicecontentdata.map(ele => {
          return (
            <PageHeadingContent heading={ele.heading} subtitle={ele.subtitle} content={ele.content} contentTwo={ele.contentTwo} />
          )
        })
      }
      <div className='before-row-margin-lm'></div>

      <div className="container-fluid">
        <div className="container">
          <div className="row service-inner-card-container">
            {
              cardcontentdata.map(ele => {
                return (
                  <ContentCards cardTitle={ele.cardTitle} cardContent={ele.cardContent} button={<a className="service-inner-card-button" href="javascript:void(0)" onClick={() => navigate(ele.link)} >Know more</a>} />
                )
              })
            }
          </div>
        </div>
      </div>

      <InnerPageBanner BannerImgClass="service-inner-banner-1-img" BannerContent="At SharepointEmpower, we are committed to delivering the highest levels of customer satisfaction. We work closely with our clients to understand their needs and requirements, and we strive to exceed their expectations with every project. Our goal is to help businesses leverage the power of software to achieve their business objectives and drive growth and success." />
      {/* <CollaborativePartners /> */}

      <Footer />
    </div>
  )
}

export default SoftwareDevelopmentService