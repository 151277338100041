import React, { useEffect, useState } from 'react'
import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent'
import InnerPageBanner from '../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'
import CollaborativePartners from '../CommonComponents/CollaborativePartners/CollaborativePartners'

import SmallContentCard from '../CommonComponents/ContentCards/SmallContentCard'
import { QaTestingCardContentData, QaTestingFeatureContentData, QaTestingSmallCardData } from './servicesData'
import ContentCards from '../CommonComponents/ContentCards/ContentCards'
import FeatureCardRows from '../CommonComponents/ContentCards/FeatureCardRows'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import TestingCarousel from '../Slider/TestingCarousel'
import ScrollAnimation from 'react-animate-on-scroll';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import Magamenu from '../Navbar/Megamenu'

function QATesting() {

    const [qatestingsmallcarddata, setQaTestingSmallCardData] = useState([])
    const [qatestingcardcontentdata, setQaTestingCardContentData] = useState([])
    const [qatestingfeaturecarddata, setQaTestingFeatureContentData] = useState([])

    useEffect(() => {
        document.title = "Software quality assurance testing services";
        setQaTestingSmallCardData(QaTestingSmallCardData)
        setQaTestingCardContentData(QaTestingCardContentData)
        setQaTestingFeatureContentData(QaTestingFeatureContentData)
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/qa-testing"
                metaTitle="Software quality assurance testing services"
                metaDescription="Enhance the quality of your software with our comprehensive quality assurance testing services. Our expert team ensures your software meets the highest standards of functionality, reliability, and performance."
                metaKeywords="software testing services company, best software testing company, QA & testing, Quality assurance, testing, software quality assurance services, software testing and quality assurance, software qa services, software qa company"
            />

            <Magamenu />
            <PageHeadingContent heading="Software Testing and Quality Assurance" subtitle="Delivering quality software solutions through rigorous testing and quality assurance processes"
                content="Our software testing and quality assurance services help businesses ensure that their software is of the highest quality. We use a variety of testing methodologies to identify bugs and other issues, and we work closely with clients to ensure that their software meets their quality standards. Our team of experienced software testers uses the latest tools and methodologies to perform comprehensive testing and quality assurance services. We work closely with our clients to understand their specific requirements and objectives, and we tailor our services to meet their needs." />

            <InnerPageBanner BannerImgClass="qa-and-testing-1-img" BannerContent="Our commitment to continuous improvement is at the heart of our quality assurance approach. We regularly evaluate our processes and methodologies to ensure that they are effective and efficient, and we work closely with our clients to implement any necessary changes to improve software quality. Our approach to quality assurance has helped us to build a reputation for delivering high-quality software products that meet the needs of our clients." />

            <div className="container-fluid">
                <div className="container">
                    <div className="row about-us-inner-card-container kpi-card-row-2-anim" >
                        {
                            qatestingsmallcarddata.map(ele => {
                                return (
                                    <SmallContentCard cardTitle={ele.cardTitle} cardSubtitle={ele.cardSubtitle} cardContent={ele.cardContent} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                            <div className="light-mode-title">
                                <h4 className="h4-sontentsubtitle-lm h5-title-anim">
                                    Tools we use
                                </h4>
                            </div>

                        </div>
                    </div>

                    <div className="row tech-icon-box-1-anim">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <TestingCarousel />
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="before-row-margin-lm"></div>
                    <div className="row service-inner-card-container">

                        <h4 className='h4-sontentsubtitle-lm'>Software Testing and QA Services</h4>

                        {
                            qatestingcardcontentdata.map(ele => {
                                return (
                                    <ContentCards cardTitle={ele.cardTitle} cardContent={ele.cardContent} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>


            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                            <div className="light-mode-title">
                                <h5 className="h5-title-lm text-align-center second-h1-anim">
                                    Automation Testing
                                </h5>
                            </div>


                            <div className="light-mode-content">
                                <p className="p-justify-content-lm second-p-anim">
                                    Our automated testing experts strategize your organization's needs and recommend
                                    technology solutions that reduce costs, speed time to market and dramatically improve
                                    end product quality. Our diverse experience with multiple test automation tools helps
                                    organizations choose the best tool according to their needs and budget. Automation
                                    testing is a software testing technique that involves using software tools to execute
                                    tests, compare results with expected outcomes, and report on the success or failure of
                                    tests. Automation testing is especially useful for regression testing, which involves
                                    running a set of tests repeatedly to ensure that new changes do not break existing
                                    functionality.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                            <div className="light-mode-title">
                                <h5 className="h5-title-lm text-align-center third-h1-anim">
                                    Automation Testing Benefits
                                </h5>
                            </div>

                        </div>
                    </div>

                    <div className="row service-icon-container-lm product-feature-row-1-anim">
                        {
                            qatestingfeaturecarddata.map(ele => {
                                return (
                                    <FeatureCardRows iconName={ele.iconName} title={ele.title} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <InnerPageBanner BannerImgClass="qa-and-testing-2-img" BannerContent="we help our clients ensure that their software applications are of the highest quality, free of bugs and errors, and meet the needs of their users. Our software testing and QA services are designed to provide a comprehensive assessment of our clients' software applications, identifying issues and providing recommendations for improvement. We offer a range of software testing and QA services, including functional testing, performance testing, security testing, usability testing, and user interface testing." />

            {/* <CollaborativePartners /> */}

            <Footer />
        </div>
    )
}

export default QATesting