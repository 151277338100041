import React, { useEffect, useState } from 'react'
import { AuditfeatureData } from './data'
import Navbar from '../Navbar/Navbar'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import ProductPage from './ProductPage/ProductPage'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from '../Navbar/Megamenu';

const AuditInspection = () => {

  const [auditfeaturedata, setAuditfeatureData] = useState([])

  useEffect(() => {
    document.title = "Audit Inspection";
    setAuditfeatureData(AuditfeatureData)
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>

      <ReactHelmetTag
        link="https://www.sharepointempower.com/audit-inspection"
        metaTitle="Inspection management software to manage risk, incident and action"
        metaDescription="Enhance productivity and accountability with our SharePoint Action Management System. Streamline task tracking, assignment, and monitoring processes for efficient action management."
        metaKeywords="Audit inspection, Sharepoint Audit inspection, Inspection management software, SharePoint risk management system, SharePoint action management system, SharePoint incident management system"
      />

      <Magamenu />
      <LazyLoadComponent>
        <ProductPage project="Audit Inspection" />
      </LazyLoadComponent>
    </div >
  )
}

export default AuditInspection