import React, { useEffect, useState } from 'react'

import CollaborativePartners from '../CommonComponents/CollaborativePartners/CollaborativePartners'
import InnerPageBanner from '../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'
import PageHeadingContent from "../CommonComponents/PageHeadingContent/PageHeadingContent"
import { CardContainerData } from './data'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import ScrollAnimation from 'react-animate-on-scroll';
import ClientsAcrossTheGlobe from '../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe'
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import Magamenu from '../Navbar/Megamenu'

const WhoWeAre = (props) => {

  const [carddata, setCardData] = useState([])

  useEffect(() => {
    document.title = "About us";
    setCardData(CardContainerData)
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='mt-7'>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/who-we-are"
        metaKeywords="about us, About sharepointempower, Who are we?, Sharepointempower about us"
      />

      <Magamenu />
      <div classNameName="light-mode-service-subtract-bg"></div>
      <PageHeadingContent heading="Who we are?" subtitle="We are your partner in success" />

      <InnerPageBanner BannerImgClass="about-us-inner-banner-1-img banner-fontsize" BannerContent="We are believers of change! A change driven by technology and innovation. We help businesses and individuals in adapting as well as adapting digital transformation. Our aim is to improve businesses with our progressive and innovative technology solutions." />

      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="light-mode-content">
                <p className="p-center-content-lm second-p-anim">
                  We are a digital transformation consultancy and engineering company that delivers solutions for global
                  organizations and technology startups. Since 2021 We have been helping companies and established brands
                  re-imagine their business through digitalization.
                  Nearly every organization will need to become a tech company in order to compete tomorrow.
                </p>
                <p className="p-center-content-lm second-p-anim">
                  At <b>SharepointEmpower </b>, we are on a mission to help companies develop competitiveness and agility
                  using the software.
                  We are a company with a mission to solve complex software engineering problems. We are dedicated to addressing intricate business automation challenges, having successfully partnered with a diverse range of clients, from startups that have gone public to Fortune 500 companies and forward-thinking enterprises.

                  Our Product Innovation Center transforms your engineering from being bottlenecks to growth drivers. We
                  help you to identify and solve critical business challenges with proven technology practices such as
                  DevOps, cloud-native development, and quality engineering services. Our remote agile teams of engineers
                  immerse themselves in your project, maintaining your company culture and working in line with your
                  strategic goals.
                </p>
              </div>
            </div>
          </div>

          <div className='before-row-margin-lm'></div>
          {/* <!-- Row 1 start --> */}
          <div className="row about-us-inner-card-container kpi-card-row-2-anim">
            {
              carddata.map(ele => {
                return (
                  <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="about-us-inner-card-body d-flex-center-custom">
                      <span className="about-us-inner-card-title">

                        {ele.cardTitle === 'fa fa-globe' ?
                          <i className={ele.cardTitle} aria-hidden='true'></i>
                          :
                          ele.cardTitle
                        }
                      </span>
                      <span className="about-us-inner-card-subtitle">{ele.cardSubtitle}</span>
                      <p className="about-us-inner-card-content">
                        {ele.cardContent}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {/* <!-- Row 1 end --> */}

          <div className="row">
            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="light-mode-content">
                <p className="p-center-content-lm third-p-anim">
                  We pride ourselves on creating a culture and environment that fosters creativity, collaboration, and
                  innovation. We believe that by providing our team members with the tools, resources, and support they
                  need to succeed, we can deliver the best possible results for our clients.
                </p>
              </div>
            </div>
          </div>
          <div className="before-row-margin-lm"></div>
        </div>
      </div>

      {/* <CollaborativePartners /> */}
      <ClientsAcrossTheGlobe />

      <Footer />
    </div>
  )
}

export default WhoWeAre