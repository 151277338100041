import React from 'react';
import { useContext, useEffect, useState } from 'react';
import Navbar from '../../Navbar/Navbar';
import useLocalStorage from 'use-local-storage';
import { ApiDataContext } from '../../CommonComponents/Userguide/ApiDataContext';
import { Link } from 'react-router-dom/dist';
import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Allproducts.css';
import Footer from '../../Footer/Footer';
import ClientsAcrossTheGlobe from '../../CommonComponents/ClientsAcrossTheGlobe/ClientsAcrossTheGlobe';
import ProductOverview from '../ProductPage/ProductOverview';
import Magamenu from '../../Navbar/Megamenu';
import useWindowSize from '../../CommonComponents/CommonFuction/useWindowSize';

export default function Allproducts() {
    const { apiProductPagedata } = useContext(ApiDataContext);
    const [theme, setTheme] = useLocalStorage('theme', 'light');
    const [loading, setLoading] = useState(true);
    const { windowWidth } = useWindowSize();

    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    useEffect(() => {
        document.title = "Products";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (apiProductPagedata && apiProductPagedata.length > 0) {
            setLoading(false);
        }
    }, [apiProductPagedata]);

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    };

    function getLogoForProduct(productName) {

        switch (productName) {
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../../assets/images/icons/LMP-b.png') : require('../../../assets/images/icons/LMP-w.png');
            case 'Asset Management System':
                return theme === 'light' ? require('../../../assets/images/icons/AMS-b.png') : require('../../../assets/images/icons/AMS-w.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../../assets/images/icons/audit-b.png') : require('../../../assets/images/icons/audit-w.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../../assets/images/icons/CMS-b.png') : require('../../../assets/images/icons/CMS-w.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../../assets/images/icons/spe-Intranet-b.png') : require('../../../assets/images/icons/spe-Intranet-w.png');
            case 'Helpdesk System':
                return theme === 'light' ? require('../../../assets/images/icons/HD-b.png') : require('../../../assets/images/icons/HD-w.png');
            case 'Leave Management System':
                return theme === 'light' ? require('../../../assets/images/icons/leave-ms-b.png') : require('../../../assets/images/icons/leave-ms-w.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../../assets/images/icons/IMS-b.png') : require('../../../assets/images/icons/IMS-w.png');
            case 'Approval System':
                return theme === 'light' ? require('../../../assets/images/icons/AS-b.png') : require('../../../assets/images/icons/AS-w.png');

            default:
                return null;
        }
    }

    const getdetailProduct = (productName) => {
        switch (productName) {
            case 'Asset Management System':
                return '/asset-management-system';
            case 'Learning Management Portal':
                return '/learning-management-system';
            case 'Audit Inspection':
                return '/audit-inspection';
            case 'Construction Management System':
                return '/construction-management-system';
            case 'SPE Intranet':
                return '/spe-intranet';
            case 'HelpDesk System':
                return '/helpdesk-system';
            case 'Leave Management System':
                return '/leave-management-system';
            case 'Inventory Management System':
                return '/inventory-management-system';
            case 'Approval System':
                return '/approval-system';

            default:
                return null;
        }
    }

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
                {/* <img className='backimgproducts' src={require("../../../assets/images/about_us_card_0.1.png")} alt="" /> */}
                <video id='myVideo' height="100%" width="100%" autoPlay loop muted playsInline preload="auto" className='backimgproducts'>
                    <source src='https://www.sharepointempower.com/assets/videos/LandingPageVideo/sharepointEmpower-2.mp4' type='video/mp4' />
                </video>
            </div>
            <Magamenu />
            <div className='allproductpage'>
                <div className='d-flex flex-row container justify-content-between mt-8'>
                    <div>
                        <h3 className=' allfirst-section-margin-top-for-navbar' style={{ fontFamily: 'Open Sans', fontWeight: '600' }}>Explore Our M365 Certified & Modern SharePoint Framework (SPFx) Solutions</h3>
                    </div>
                    <div className="light-mode-title productquotationbtn d-flex align-items-center pr-4">
                        <Link to="/faqs" className='pricingfaq'>
                            <i className="fa fa-circle-question mr-1" />
                            <span className='m-0'>FAQs</span>
                        </Link>
                    </div>
                </div>

                <ProductOverview />
                <ClientsAcrossTheGlobe />
                <Footer />

            </div>

        </>
    );
}
