import React, { useEffect } from 'react'
import PageHeadingContent from '../CommonComponents/PageHeadingContent/PageHeadingContent'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag';
import Magamenu from '../Navbar/Megamenu';

const FindUs = () => {
    useEffect(() => {
        document.title = "Find us";
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='mt-7'>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/find-us"
                metaKeywords="find us here"
            />

            <Magamenu />
            <PageHeadingContent heading="Find us" />

            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div className="before-row-margin-lm"></div>

                        <div className="row form-data-anim">
                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <iframe className="find-us-map-iframe"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.1557175780554!2d70.77789041541519!3d22.27209044954546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cbb42911224b%3A0x92adaf4e004e42e7!2sSharepointEmpower%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1677222768603!5m2!1sen!2sin&z=100"
                                    width="600" height="550" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>

                        <div className="row findus-details form-data-anim">
                            <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="company-details-container">
                                    <ul>
                                        <li>
                                            <LazyLoadImage
                                                className="contact-us-logo"
                                                src={require("../../assets/images/SPE_logo_white_navbar.png")}
                                                alt="SPE_logo_white"
                                                effect="blur"
                                                visibleByDefault={require("../../assets/images/SPE_logo_white_navbar.png")}
                                            />
                                        </li>
                                        <li>
                                            602,603,604, RK Prime - 2, 150 Feet Ring Rd, Rajkot, Gujarat
                                            360004
                                        </li>
                                        <li>
                                            <ul className="social-media-logo-icon">
                                                <li>
                                                    <a href="https://in.linkedin.com/company/sharepointempower"
                                                        target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-linkedin"></i></a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/sharepointempower_/?hl=en" target="_blank"
                                                        rel="noreferrer noopener"><i className="fa-brands fa-square-instagram"></i></a>
                                                </li>
                                                <li>
                                                    <a href="https://www.youtube.com/@sharepointempower/featured" target="_blank"
                                                        rel="noreferrer noopener"><i className="fa-brands fa-square-youtube"></i></a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="company-details-container">
                                    <ul>
                                        <li><b>Contact Information</b></li>
                                        <li><a href="tel:9429048910">+91 9429048910</a></li>
                                        <li><a href="mailto:support@sharepointempower.com">support@sharepointempower.com</a></li>
                                        <li>
                                            <a href='https://www.sharepointempower.com/assets/docs/SharePointEmpower_Congratulatory_Letter.pdf' target='_blank'>
                                                <LazyLoadImage
                                                    className="microsoft-icon bg-black"
                                                    src={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                                    alt="Microsoft-solution-partner"
                                                    effect="blur"
                                                    visibleByDefault={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default FindUs