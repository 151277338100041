import React, { useEffect, useState } from 'react'
import MasterPage from './components/MasterPage/MasterPage';
import "primereact/resources/primereact.min.css";
import "/node_modules/primeflex/primeflex.css";
import Snowfall from 'react-snowfall';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ContactUsForm from './components/About us/ContactUsForm';
import ApiDataProvider from './components/CommonComponents/Userguide/ApiDataContext';
import useWindowSize from './components/CommonComponents/CommonFuction/useWindowSize';

function App() {

  const [showSnowfall, setShowSnowfall] = useState(true);
  const [visible, setVisible] = useState(false);
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startdate = new Date(`${currentYear}-12-24T00:00:00`);
    const enddate = new Date(startdate);
    enddate.setDate(startdate.getDate() + 10);

    const now = new Date();
    if (now >= startdate && now <= enddate) {
      setShowSnowfall(true);
    } else {
      setShowSnowfall(false);
    }
  }, []);

  return (
    <div>
      <ApiDataProvider>
        <MasterPage />
        <div className="right-btn-title">
          {windowWidth > 499 ? <Button label="Get in touch" onClick={() => setVisible(true)} className='btn-get-in-touch' /> :
            <i class="fa-solid fa-address-book" onClick={() => setVisible(true)}></i>}
          <Dialog
            visible={visible}
            header='Contact Us'
            modal
            className='contactusdailog'
            dismissableMask={() => setVisible(true)}
            onHide={() => { if (!visible) return; setVisible(false); }}
            blockScroll={true}
          >
            <ContactUsForm />
          </Dialog>
        </div>
        <div>
          {showSnowfall && (
            <Snowfall snowflakeCount={200} speed={[0.5, 1]} radius={[0.5, 1.5]} color='#BEDCFF' wind={[0, 0.5]} style={{
              position: 'fixed',
            }} />
          )}
        </div>
      </ApiDataProvider>
    </div>
  );
}

export default App;