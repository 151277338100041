import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';

function FeatureCardRows(props) {
    return (
        <div className="marginbtm2p col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="service-icon-box-lm d-flex-center-custom">
                    <div>
                        <ul>
                            <li className="text-align-center product-management-icon">
                                <img src={require(`../../../assets/images/icons/${props.iconName}.png`)}
                                    alt={props.iconName} />
                            </li>
                            <li className="d-flex-center-custom"><span className="icon-card-span text-align-center">{props.title}</span></li>
                        </ul>
                    </div>
                </div>
        </div>
    )
}

export default FeatureCardRows