import React, { useEffect, useState } from 'react'
import PageHeadingContent from '../../CommonComponents/PageHeadingContent/PageHeadingContent'
import InnerPageBanner from '../../CommonComponents/BackgroundBanners/InnerPageBanner/InnerPageBanner'
import CollaborativePartners from '../../CommonComponents/CollaborativePartners/CollaborativePartners'
import SmallFooterBanner from '../../CommonComponents/BackgroundBanners/SmallFooterBanner/SmallFooterBanner'
import ContentCards from '../../CommonComponents/ContentCards/ContentCards'
import { WebAppDevCardContentData } from './WebAppDevData'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import WebApplicationCarousel from '../../Slider/WebApplicationCarousel'
import ReactHelmetTag from '../../CommonComponents/ReactHelmet/ReactHelmetTag'
import Magamenu from '../../Navbar/Megamenu'

const WebApplicationDevelopment = () => {

  const [webappdevcardcontentdata, setWebAppDevCardContentData] = useState([])

  useEffect(() => {
    setWebAppDevCardContentData(WebAppDevCardContentData)
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ReactHelmetTag
        link="https://www.sharepointempower.com/web-application-development-services"
        metaTitle="Web application development services"
        metaDescription="We specialize in creating robust, secure, and user-friendly web applications tailored to your specific business needs. Unlock your digital potential with our web application development services today!"
        metaKeywords="web application development company, web application development services, custom web application development company, custom web application development services"
      />

      <Magamenu />
      <PageHeadingContent heading="Web Application Development" subtitle="Elevating your online presence with intuitive web applications"
        content="We use the latest technologies and frameworks to develop web applications that are performant, scalable, and easy to maintain. Our front-end development team creates intuitive user interfaces that provide a seamless user experience across different devices and platforms. We take into account accessibility and cross-browser compatibility to ensure that our web applications are accessible to all users."
        contentTwo="Our back-end development capabilities are equally impressive. We use secure programming practices to ensure that our web applications are safe and protected from malicious attacks. Our team is proficient in server-side programming, database design and management, and API development, allowing us to create web applications that integrate with other systems and services."
      />

      <InnerPageBanner BannerImgClass="web-app-app-1-img" BannerContent="At our web application development service, we specialize in building custom web-based applications that meet the unique needs of our clients. Our team of experienced web developers uses the latest technologies and best practices to create applications that are secure, scalable, and easy to use." />

      <div className="container-fluid">
        <div className="container">
          <div className="before-row-margin-lm"></div>
          <div className="row">
            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="light-mode-title">
                <h4 className="h4-sontentsubtitle-lm h5-title-anim">
                  Technologies we have worked with
                </h4>
              </div>
            </div>
          </div>
          <div className="row tech-icon-box-1-anim">
            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <WebApplicationCarousel />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="before-row-margin-lm"></div>
          <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <div className="light-mode-title">
              <h4 className="h4-sontentsubtitle-lm h5-title-anim">
                Web application development services
              </h4>
            </div>
          </div>
          <div className="row service-inner-card-container">
            {
              webappdevcardcontentdata.map(ele => {
                return (
                  <ContentCards cardTitle={ele.cardTitle} cardContent={ele.cardContent} />
                )
              })
            }
          </div>
        </div>
      </div>

      {/* <CollaborativePartners /> */}

      <Footer />
    </div>
  )
}

export default WebApplicationDevelopment