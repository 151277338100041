import React, { useEffect } from 'react'
import CollaborativePartners from '../CommonComponents/CollaborativePartners/CollaborativePartners'
import Navbar from '../Navbar/Navbar';
import Footer from './Footer';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag';
import Magamenu from '../Navbar/Megamenu';

function PrivacyPolicy() {
    useEffect(() => {
        document.title = "Privacy policy";
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com/PrivacyPolicy"
            />

            <Magamenu />
            <div className="container-fluid">
                <div className="light-mode-subtract-bg"></div>
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div id="first-section-margin-top-for-navbar" className="light-mode-title">
                                <div className='mt-8'>
                                    <h1 className="h1-tag-lm first-h1-anim">Privacy Policy</h1>
                                </div>
                            </div>
                            <div className="light-mode-subtitle">
                                <h2 className="h2-subtitle-lm first-h2-anim">
                                    SharePoint and Office 365 Applications, Solutions, and Add-ins
                                </h2>
                            </div>
                            <div className="light-mode-content">
                                <p className="p-content-lm first-p-anim">
                                    Users' privacy is important to us, and so is being transparent about how we collect,
                                    use, and share information about you. This Privacy Policy covers the information we
                                    collect about you when you use SharepointEmpower Pvt. Ltd. Application and Website for
                                    SharePoint.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">

                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                        <div className="policy-list-container">
                            <ol>

                                <li>
                                    <h5>Overview</h5>
                                    <ul className="policy-ul-container">
                                        <li>Thank you for visiting SharepointEmpower and reviewing our Privacy Policy. Your
                                            privacy is important to us, and our policy is simple: we will collect no
                                            personally identifiable information about you when you visit the Web Site unless
                                            you choose to provide that information</li>
                                        <li>This Privacy Policy does not describe information collection practices on other
                                            sites, including those linked to or from the Web Site.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>What information does SharepointEmpower collect?</h5>
                                    <ul className="policy-ul-container">
                                        <li>While requesting information or trial registration through the app, we will ask
                                            you to provide basic contact information.</li>
                                        <li>SharepointEmpower Pvt. Ltd. uses common internet technologies, such as cookies,
                                            to anonymously manage aggregated in-app usage patterns to provide the best
                                            possible experience with the app</li>
                                        <li>SharepointEmpower Pvt. Ltd. basic anonymized usage statistics for the
                                            subscription/licensing track.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>How do we use the information?</h5>
                                    <ul className="policy-ul-container">
                                        <li>We do not share or sell any information to advertisement providers and third
                                            parties.</li>
                                        <li>We use the information we collect to provide you with the products we offer,
                                            which includes using data to improve and personalize your experiences.</li>
                                        <li>We also may use the data to communicate with you, during support consulting or
                                            troubleshooting sessions and share new product updates with you.</li>
                                        <li>However, we do not use what you say in email, chat, video calls, or voice mail,
                                            or your documents, photos, or other personal files to target third-party ads to
                                            you.</li>
                                        <li>As you are our valuable customer, we may use your organization name and logo on
                                            our customer list.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>How to Access & Control Your Personal Data?</h5>
                                    <ul className="policy-ul-container">
                                        <li>You can also opt out of receiving advertising emails from us by clicking the
                                            unsubscribe link in our emails.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>How do we protect visitor information?</h5>
                                    <ul className="policy-ul-container">
                                        <li>Our website and apps are scanned regularly for security holes and known
                                            vulnerabilities to make your visit to our site as safe as possible. We use
                                            regular Malware Scanning. If you suspect unauthorized access to your data,
                                            please contact SharepointEmpower Pvt. Ltd.</li>
                                        <li>We do not have any commercial/non-profit advertisements on our site or apps.
                                        </li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Your data protection right.</h5>
                                    <ul className="policy-ul-container">
                                        <li>You have the right to request from us at any time information about your data
                                            stored with us, their origin, recipients or categories of recipients to whom it
                                            has been transmitted, and the purposes of the processing of personal data. You
                                            have the right to access your data.</li>
                                        <li>You have the right to correct inaccurate personal data concerning you without
                                            undue delay. You have the right to complete incomplete personal data, including
                                            by providing an additional statement.</li>
                                        <li>You have the right to delete your data within 1 month of the end of the
                                            subscription. If the erasure would be contrary to legal, tax-legal, or other
                                            legal reasons, the place of erasure of your data may only be limited by their
                                            processing.</li>
                                        <li>You have the right to restrict the processing of your data.</li>
                                        <li>If you require to obtain personal data that we process, we will pass it to you
                                            or another administrator or processor mark in a structured, commonly used, and
                                            machine-readable format. In this way, we will only transfer your data to another
                                            administrator if technically possible</li>
                                        <li>You have the right, for reasons relating to your particular situation, to object
                                            at any time to the processing of personal data concerning you.</li>
                                        <li>When contacting us by e-mail or post on the contact specified in the header of
                                            this document, we will store the information you have communicated (your e-mail
                                            address, your name, or address and telephone number) to answer your questions.
                                            We will delete the data stored in this way after the reason for their storage
                                            has ceased, or we will limit their processing if there is a legal obligation to
                                            keep them.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Indemnification</h5>
                                    <ul className="policy-ul-container">
                                        <li>You agree to indemnify, defend and hold harmless the Web Site and its members,
                                            managers, officers, employees, agents, and the assigns of same, from and against
                                            any loss, costs, expenses (including reasonable attorneys' fees and expenses),
                                            claims, damages and liabilities related to or associated with your use of the
                                            Web Site and any violation of this Privacy Policy by you.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Governing Law and Jurisdiction</h5>
                                    <ul className="policy-ul-container">
                                        <li>This Privacy Policy constitutes a contract made under and shall be governed by
                                            and construed by the laws of India.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Last Update</h5>
                                    <ul className="policy-ul-container">
                                        <li>This Privacy Policy was last updated on 10 January, 2023.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Contact Information</h5>
                                    <ul className="policy-ul-container">
                                        <li>Questions or comments regarding the Web Site should be sent by e-mail to
                                            support@sharepointempower.com</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Copyrights and Trademarks</h5>
                                    <ul className="policy-ul-container">
                                        <li>All materials contained on the Web Site are Copyright January 31, 2023,
                                            SHAREPOINTEMPOWER PVT. LTD. All rights reserved.</li>
                                        <li>No person is authorized to use, copy or distribute any portion of the Web Site
                                            and Add-In including related graphics.</li>
                                        <li>SHAREPOINTEMPOWER PVT. LTD. and other trademarks and/or service marks (including
                                            logos and designs) found on the Web Site are trademarks/service marks that
                                            identify SHAREPOINTEMPOWER PVT. LTD. and the goods and/or services provided by
                                            SHAREPOINTEMPOWER PVT. LTD. Such marks may not be used under any circumstances
                                            without the prior written authorization of SHAREPOINTEMPOWER PVT. LTD.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Links to Third-Party Web Site</h5>
                                    <ul className="policy-ul-container">
                                        <li>SHAREPOINTEMPOWER PVT. LTD may provide hyperlinks to third-party websites as a
                                            convenience to users of the Web Site.</li>
                                        <li>SHAREPOINTEMPOWER PVT. LTD. does not control third-party websites and is not
                                            responsible for the contents of any linked-to, third-party websites, or any
                                            hyperlink in a linked-to website.</li>
                                        <li>SHAREPOINTEMPOWER PVT. LTD. does not endorse, recommend, or approve any
                                            third-party website hyperlinked from the Web Site.</li>
                                        <li>SHAREPOINTEMPOWER PVT. LTD. will have no liability to any entity for the content
                                            or use of the content available through such hyperlinks.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>No Representations or Warranties; Limitations on Liability</h5>
                                    <ul className="policy-ul-container">
                                        <li>The information and materials on the Web Site could include technical
                                            inaccuracies or typographical errors. Changes are periodically made to the
                                            information contained herein.</li>
                                        <li>SHAREPOINTEMPOWER PVT. LTD. MAKES NO REPRESENTATIONS OR WARRANTIES CONCERNING
                                            ANY INFORMATION, MATERIALS, OR GRAPHICS ON THE WEBSITE, ALL OF WHICH IS PROVIDED
                                            ON A STRICTLY "AS IS" BASIS, WITHOUT WARRANTY OF ANY KIND AND HEREBY EXPRESSLY
                                            DISCLAIMS ALL WARRANTIES ABOUT ANY INFORMATION, MATERIALS, OR GRAPHICS ON THE
                                            WEB SITE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                                            PARTICULAR PURPOSE AND NON-INFRINGEMENT.</li>
                                        <li>UNDER NO CIRCUMSTANCES SHALL THE SITE OWNER OR PUBLISHER BE LIABLE UNDER ANY
                                            THEORY OF RECOVERY, AT LAW OR IN EQUITY, FOR ANY DAMAGES, INCLUDING WITHOUT
                                            LIMITATION, SPECIAL, DIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
                                            (INCLUDING, BUT NOT LIMITED TO LOSS OF USE OR LOST PROFITS), ARISING OUT OF OR
                                            IN ANY MANNER CONNECTED WITH THE USE OF INFORMATION OR SERVICES, OR THE FAILURE
                                            TO PROVIDE INFORMATION OR SERVICES, FROM THE WEB SITE.</li>
                                    </ul>
                                </li>
                                <br />
                                <li>
                                    <h5>Changes to These Terms of Use</h5>
                                    <ul className="policy-ul-container">
                                        <li>SHAREPOINTEMPOWER PVT. LTD. reserves the right to change these Terms of Use at
                                            any time by posting new Terms of Use at this location.</li>
                                        <li>You can send an e-mail to SHAREPOINTEMPOWER PVT. LTD. with any questions
                                            relating to these Terms of Use at support@sharepointempower.com.</li>
                                    </ul>
                                </li>


                            </ol>

                        </div>
                    </div>


                    <div className="col col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>

                </div>

            </div>

            {/* <CollaborativePartners /> */}
            <Footer />
        </div >
    )
}

export default PrivacyPolicy