import React, { useContext, useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Nav, Sidenav } from "rsuite";
import { ApiDataContext } from "./ApiDataContext";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Galleria } from 'primereact/galleria';
import { Button } from 'primereact/button';
import html2pdf from 'html2pdf.js';
import { DeferredContent } from 'primereact/deferredcontent';
import Navbar from "../../Navbar/Navbar";
import { ScrollTop } from 'primereact/scrolltop';
import './Allprojects.css';
import { BreadCrumb } from 'primereact/breadcrumb';
import Footer from "../../Footer/Footer";
import Magamenu from "../../Navbar/Megamenu";
import useWindowSize from "../CommonFuction/useWindowSize";

export default function AllProjects({ project }) {
  const [level2Categories, setLevel2Categories] = useState([]);
  const [level3Categories, setLevel3Categories] = useState([]);
  const [description, setDescription] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedLevel2, setSelectedLevel2] = useState(null);
  const [selectedLevel3Category, setSelectedLevel3Category] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [imgUrls, setImgUrls] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [openItem, setOpenItem] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);

  const inputRef = useRef(null);
  const descriptionContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const galleria = useRef(null);

  const { apidata } = useContext(ApiDataContext);
  const { level1category, level2categoryitem, level3categoryitem } = useParams();
  const navigatation = useNavigate();
  const { windowWidth } = useWindowSize();

  const isMobileScreen = () => windowWidth < 992;

  const toggleSidenav = () => {
    setIsSidenavOpen(!isSidenavOpen);
  };

  // extract image URL from Description
  useEffect(() => {
    const extractImgUrls = () => {
      const tempImgUrls = [];
      const regex = /<img[^>]+src="([^">]+)"/g;
      let match;
      while ((match = regex.exec(description)) !== null) {
        const itemImageSrc = match[1].replace(/&#58;/g, ":");
        const thumbnailImageSrc = itemImageSrc.replace('/Images/', '/Thumbnails/');
        tempImgUrls?.push({ itemImageSrc, thumbnailImageSrc });
      }
      setImgUrls(tempImgUrls);
    };

    extractImgUrls();
  }, [description]);

  const handleImageClick = async (event) => {
    if (event.target.tagName === 'IMG') {
      const index = imgUrls.findIndex(img => img.itemImageSrc === event.target.src);
      await setSelectedIndex(index);
      galleria.current.show();
    }
  };

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.show();
    }
  }, []);

  // image click crousal
  const itemTemplate = (item) => {
    return (
      <img
        src={item?.itemImageSrc}
        alt={item?.alt}
        style={{ width: '75%', height: '100%', display: 'block' }}
      />
    );
  };

  const onItemChange = (event) => {
    setSelectedIndex(event.index)
  }

  const renderFooter = () => {

    return (
      <div className="flex justify-content-center text-white">
        {
          imgUrls && imgUrls?.length > 0 && (
            <span className="p-3">
              <span className="p-2">{selectedIndex + 1}/{imgUrls?.length}</span>
              <span className="p-2">{selectedLevel2} {selectedLevel3Category && ` / ${selectedLevel3Category}`}</span>
            </span>
          )
        }
      </div>
    );
  }

  const footer = renderFooter();

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item?.thumbnailImageSrc}
        alt={item?.alt}
        style={{ display: 'block' }}
      />
    );
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedSuggestionIndex(prevIndex => Math.max(prevIndex - 1, 0));
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedSuggestionIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1));
      } else if (event.key === 'Enter') {
        event.preventDefault();
        if (selectedSuggestionIndex !== -1) {
          handleSuggestionSelect(suggestions[selectedSuggestionIndex]);
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSuggestionIndex, suggestions.length]);

  useEffect(() => {
    if (inputRef.current) {
      const selectedSuggestion = inputRef.current.querySelector('.selected');
      if (selectedSuggestion) {
        selectedSuggestion.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedSuggestionIndex]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSuggestions([]);
        setSelectedSuggestionIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {

    // Load level2 and level3 data
    level2();
    level3();
    reloddisplay();

    // Load default content if no level1 category is selected
    if (!level1category) {
      reloddisplay("introduction");
      navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/introduction`);
    }

    // Load description for selected level2 category
    if (level2categoryitem) {
      const selectedData = apidata?.find(
        (item) =>
          (item.projectName === project || item.projectName == "Permission" || item.projectName == "Uninstall" || item.projectName == "Upgrade" || item.projectName == "Enable External Sharing" || item.projectName == "Activate License" || item.projectName == "Teams") &&
          item.level1Category.toLowerCase().replace(/\s/g, '-') === level1category &&
          item.level2Category.toLowerCase().replace(/\s/g, '-') === level2categoryitem
      );
      setDescription(selectedData ? selectedData?.description : "");
    }

    // Load description for selected level3 category
    if (level3categoryitem) {
      const selectedData = apidata?.find(
        (item) =>
          item.projectName === project &&
          item.level1Category?.toLowerCase().replace(/\s/g, '-') === level1category &&
          item.level3Category?.toLowerCase().replace(/\s/g, '-') === level3categoryitem
      );
      setDescription(selectedData ? selectedData.description : "");

      setTimeout(() => {
        setSelectedLevel3Category(selectedData?.level3Category);
      }, 100);
    }

    const projectNames = [
      "Uninstall",
      "Enable External Sharing",
      "Upgrade",
      "Activate License",
      "Teams",
      "Permission"
    ];

    if (projectNames.includes(project)) {
      const selectedData = apidata?.find(
        (item) =>
          item.projectName === project &&
          item.level1Category.toLowerCase().replace(/\s/g, '-') === level1category
      );
      setDescription(selectedData ? selectedData.description : "");
    }

  }, [apidata, project, level1category, level2categoryitem, level3categoryitem]);

  const handleCategoryChange = (e) => {
    const newCategory = e.value;
    reloddisplay();
    navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${newCategory.toLowerCase().replace(/\s/g, '-')}`);
    if (isMobileScreen()) {
      toggleSidenav();
    }
  };

  const arrowOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center justify-content-between">
        <div>{option}</div>
        <div>  <i className="fas fa-chevron-right" style={{ marginLeft: '8px' }}></i></div>
      </div>
    );
  };

  const convertTitleCase = (str) => {
    return str.replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const reloddisplay = (defaultCategory = "") => {

    let selectedData = apidata?.find(
      (item) =>
        (item.projectName === project || item.projectName == "Uninstall" || item.projectName == "Upgrade" || item.projectName == "Enable External Sharing" || item.projectName == "Activate License" || item.projectName == "Teams" || item.projectName == "Permission") &&
        item.level1Category.toLowerCase().replace(/\s/g, '-') === (level1category || defaultCategory) &&
        (item.sequence === 1 || item.sequence === 1.001 ||
          item.sequence === 1.01 ||
          item.sequence === 2.01 ||
          item.sequence === 3.01 ||
          item.sequence === 4.01 || item.sequence === 7.01 || item.sequence === 8.01 || item.sequence === 9.01 || item.sequence === 10.01 || item.sequence === 11.01 || item.sequence === 12.01)
    );

    if (selectedData) {
      // setSelectedLevel2(selectedData.level2Category);
      setDescription(selectedData.description);
    } else {
      setDescription("");
    }
  };

  const data = apidata?.filter((item) => (item.projectName === project || item.projectName == "Permission" || item.projectName == "Uninstall" || item.projectName == "Upgrade" || item.projectName == "Enable External Sharing" || item.projectName == "Activate License" || item.projectName == "Teams"));
  const projectNameTitle = apidata?.filter((item) => (item.projectName === project));
  let updateprojectname = projectNameTitle[0]?.projectName;

  if (projectNameTitle[0]?.projectName?.includes('Management') && projectNameTitle[0]?.projectName?.includes('System')) {
    updateprojectname = projectNameTitle[0]?.projectName.replace('System', '').trim();
  }

  let level1data = apidata
    ?.filter((e) => (e.projectName === project || e.projectName == "Permission" || e.projectName == "Uninstall" || e.projectName == "Upgrade" || e.projectName == "Enable External Sharing" || e.projectName == "Activate License" || e.projectName == "Teams") && e.productGuideIsActive)
    ?.map((e) => ({ category: e.level1Category, sequence: e.sequence }));

  level1data = level1data.sort((a, b) => a.sequence - b.sequence);

  const level1Categories = [...new Set(level1data?.map((e) => e.category))];

  let level2 = () => {
    const filteredLevel2Categories = apidata
      ?.filter(
        (item) =>
          (item.projectName === project || item.projectName == "Permission" || item.projectName == "Uninstall" || item.projectName == "Upgrade" || item.projectName == "Enable External Sharing" || item.projectName == "Activate License" || item.projectName == "Teams") && item.level1Category.toLowerCase().replace(/\s/g, '-') === level1category && item.productGuideIsActive
      )
      ?.map((item) => ({
        category: item.level2Category,
        level3category: item.level3Category,
        sequence: item.sequence,
        discription: item.description,
      }));

    level2 = filteredLevel2Categories.sort((a, b) => a.sequence - b.sequence);

    const categoryData = [...new Set(level2?.map((x) => x.category))];

    const level2Categoriesdata = [
      ...new Set(
        categoryData?.map((e) => ({
          level2data: e,
          level3data: level2?.filter((x) => x.category == e)[0].level3category,
        }))
      ),
    ];
    setLevel2Categories(level2Categoriesdata);
  };

  let level3 = () => {
    const filteredLevel3Categories = apidata
      ?.filter(
        (item) => (item.projectName === project && item.level3Category !== null) && item.productGuideIsActive
      )
      ?.map((item) => ({
        level1Name: item.level1Category,
        level2Name: item.level2Category,
        sequence: item.sequence,
        category: item.level3Category,
        discription: item.description,
      }));

    level3 = filteredLevel3Categories.sort((a, b) => a.sequence - b.sequence);
    setLevel3Categories(level3);
  };

  // next-Previous level2category 
  useEffect(() => {
    if (level2Categories.length > 0) {
      setCurrentIndex(level2Categories.findIndex((item) => item.level2data === selectedLevel2));
    }
  }, [level2Categories, selectedLevel2]);

  const handleNextCategory = () => {
    if (currentIndex < level2Categories.length - 1) {
      const nextCategory = level2Categories[currentIndex + 1];
      setCurrentIndex(currentIndex + 1);
      handleLevel2CategoryClick(nextCategory);
    }
  };

  const handlePreviousCategory = () => {
    if (currentIndex > 0) {
      const prevCategory = level2Categories[currentIndex - 1];
      setCurrentIndex(currentIndex - 1);
      handleLevel2CategoryClick(prevCategory);
    }
  };

  const handleLevel2CategoryClick = (category) => {

    setTimeout(() => {
      setSelectedLevel2(category.level2data);
      setSelectedLevel3Category(null);
    }, 100);

    const selectedData = data?.find(
      (item) =>
        (item.projectName === project || item.projectName == "Permission" || item.projectName == "Uninstall" || item.projectName == "Upgrade" || item.projectName == "Enable External Sharing" || item.projectName == "Activate License" || item.projectName == "Teams") &&
        item.level1Category?.toLowerCase().replace(/\s/g, '-') === level1category &&
        item.level2Category?.toLowerCase().replace(/\s/g, '-') === category.level2data?.toLowerCase().replace(/\s/g, '-')
    );

    if (selectedData) {
      setTimeout(() => {
        setDescription(selectedData.description);
      }, 100);
    } else {
      setDescription("");
    }
    navigatation(`/guidelines/${project?.toLowerCase().replace(/\s/g, '-')}/${level1category?.toLowerCase().replace(/\s/g, '-')}/${category.level2data?.toLowerCase().replace(/\s/g, '-')}`);
  };

  const handleLevel3DropdownChange = (item) => {
    setSelectedLevel3Category(item.category);
    setSelectedLevel2(item.level2Name);

    const selectedData = apidata?.find(
      (data) =>
        data.projectName === project &&
        data.level1Category?.toLowerCase().replace(/\s/g, '-') === level1category &&
        data.level3Category?.toLowerCase().replace(/\s/g, '-') === item.category.toLowerCase().replace(/\s/g, '-')
    );

    if (selectedData) {
      setDescription(selectedData.description);
    } else {
      setDescription("");
    }

    navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${item.level1Name.toLowerCase().replace(/\s/g, '-')}/${item.level2Name.toLowerCase().replace(/\s/g, '-')}/${item.category.toLowerCase().replace(/\s/g, '-')}`);
  };

  // search category
  const similarWords = ['Plan', 'Pricing'];
  const faqsWords = ['FAQ'];

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      if (query.trim() === '') {
        setSuggestions([]);
        return;
      }

      const filteredLevel1 = data
        ?.filter((category) =>
          category.level1Category.toLowerCase().includes(query.toLowerCase())
        )
        ?.map((category) => category.level1Category);

      const filteredLevel2 = data
        ?.filter((category) =>
          category?.level2Category?.toLowerCase()?.includes(query.toLowerCase())
        )
        ?.map((category) => category?.level2Category);

      const filteredLevel3 = data
        ?.filter((category) =>
          category?.level3Category?.toLowerCase()?.includes(query.toLowerCase())
        )
        ?.map((category) => category?.level3Category);

      const filteredSimilarWords = [...similarWords, ...faqsWords]?.filter(word =>
        word.toLowerCase().includes(query.toLowerCase())
      );

      const allFilteredCategories = [
        ...new Set([
          ...filteredLevel1,
          ...filteredLevel2,
          ...filteredLevel3,
          ...filteredSimilarWords,
        ]),
      ];

      setSuggestions(allFilteredCategories);
    }, 200);
    setTimeoutId(newTimeoutId);
  };

  // searchbox suggetion
  const handleSuggestionSelect = (selectedCategory) => {

    if (similarWords.includes(selectedCategory)) {
      navigatation("/product-pricing-list");
    } else if (faqsWords.includes(selectedCategory)) {
      navigatation("/faqs");
    } else {
      const matchedLevel2 = data.find(category =>
        category.level2Category.toLowerCase() === selectedCategory.toLowerCase()
      );

      const matchedLevel3 = level3Categories.find(category =>
        category.category.toLowerCase() === selectedCategory.toLowerCase()
      );

      if (matchedLevel2) {
        navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${matchedLevel2.level1Category.toLowerCase().replace(/\s/g, '-')}/${matchedLevel2.level2Category.toLowerCase().replace(/\s/g, '-')}`);
      } else if (matchedLevel3) {
        navigatation(`/guidelines/${project.toLowerCase().replace(/\s/g, '-')}/${matchedLevel3.level1Name.toLowerCase().replace(/\s/g, '-')}/${matchedLevel3.level2Name.toLowerCase().replace(/\s/g, '-')}/${matchedLevel3.category.toLowerCase().replace(/\s/g, '-')}`);
      } else {
        handleCategoryChange({ value: selectedCategory });
      }
    }
    setSuggestions([]);
    setSearchQuery('');
  };

  // PDF Download
  const handleDownloadPDF = async () => {
    const cleanedDescription = description.replaceAll(/<\/?b>/g, '');
    const replacedDescription = cleanedDescription.replaceAll('&#58;', ':');
    const replaceBdescription = replacedDescription.replace(/<b[^>]*>/g, '').replace(/<\/b>/g, '');

    const containerWithPadding = document.createElement("div");
    containerWithPadding.style.padding = "20px";

    const container = document.createElement("div");
    container.style.color = "#000000";
    container.innerHTML = replaceBdescription;

    const spanElements = container.querySelectorAll("span");
    spanElements.forEach(span => {
      span.style.color = "#000000";
    });
    const divElements = container.querySelectorAll("b");
    divElements.forEach(span => {
      span.style.color = "#000000";
    });

    const imageElements = Array.from(container.querySelectorAll("img"));

    const loadImagePromises = imageElements?.map(async img => {
      const imageUrl = img.src;
      const imageData = await fetch(imageUrl).then(res => res.blob());
      const dataUrl = await blobToDataURL(imageData);
      img.src = dataUrl;
      return new Promise(resolve => {
        img.onload = () => resolve({ height: img.height });
      });
    });

    await Promise.all(loadImagePromises);

    containerWithPadding.appendChild(container);

    const urlParts = window.location.pathname.split('/');
    const fileName = urlParts[urlParts.length - 1];

    const options = {
      margin: [18, 5, 18, 5],
      filename: `${project}_${fileName}.pdf`,
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all'] },
    };
    const logoUrl = require('../../../assets/images/SPEPDFLOGO.jpg');
    const MSPUrl = require('../../../assets/images/Microsoft-solution-partner-black.jpg');
    const globUrl = require('../../../assets/images/mdi_earth.png');
    const linkendinUrl = require('../../../assets/images/jam_linkedin.png');
    const youtubeUrl = require('../../../assets/images/mdi_youtube.png');

    // Preload images
    const [logoDataUrl, MSPlogo, globweb, linkedinlogo, youtubelogo] = await Promise.all([
      fetchImage(logoUrl),
      fetchImage(MSPUrl),
      fetchImage(globUrl),
      fetchImage(linkendinUrl),
      fetchImage(youtubeUrl),
    ]);

    html2pdf().from(containerWithPadding).set(options).toPdf().get('pdf').then((pdf) => {
      var totalPages = pdf.internal.getNumberOfPages();
      var pageHeight = pdf.internal.pageSize.getHeight();
      const downloadTime = new Date().toLocaleTimeString();
      const downloadDate = new Date().toLocaleDateString();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(150);
        pdf.text(10, pageHeight - 10, 'support@sharepointempower.com');

        pdf.addImage(globweb, 'PNG', 10, pageHeight - 7, 5, 5, '', 'FAST', null, 'NONE', { opacity: 0.5 });
        pdf.link(10, pageHeight - 7, 5, 5, { url: 'https://www.sharepointempower.com/' });

        pdf.addImage(linkedinlogo, 'PNG', 16, pageHeight - 7, 5, 5, '', 'FAST', null, 'NONE', { opacity: 0.5 });
        pdf.link(16, pageHeight - 7, 5, 5, { url: 'https://www.linkedin.com/company/sharepointempower/mycompany/' });

        pdf.addImage(youtubelogo, 'PNG', 23, pageHeight - 7, 5, 5, '', 'FAST', null, 'NONE', { opacity: 0.5 });
        pdf.link(23, pageHeight - 7, 5, 5, { url: 'https://www.youtube.com/@sharepointempower' });

        pdf.setFontSize(10);
        pdf.text(pdf.internal.pageSize.getWidth() - 105, pageHeight - 3, `${i}/${totalPages}`);

        pdf.line(0, pageHeight - 15, pdf.internal.pageSize.getWidth(), pageHeight - 15);
        pdf.line(0, 18, pdf.internal.pageSize.getWidth(), 18);

        pdf.addImage(logoDataUrl, 'JPEG', 5, 3, 50, 12);
        pdf.addImage(MSPlogo, 'JPEG', 175, 285, 28, 10);

        pdf.setFontSize(10);
        pdf.text(pdf.internal.pageSize.getWidth() - 50, 8, updateprojectname);

        pdf.setFontSize(8);
        pdf.text(pdf.internal.pageSize.getWidth() - 50, 12, `${downloadDate} ${downloadTime}`);

      }
    }).save(`${project}_${fileName}.pdf`);
    this.elementPDF.clear();

    async function fetchImage(url) {
      const imageData = await fetch(url).then(res => res.blob());
      return await blobToDataURL(imageData);
    }
  };

  const blobToDataURL = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleToggle = (level2data) => {
    setOpenItem(openItem === level2data ? null : level2data);
  };

  // copy selected category
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  }

  // breadcrumb of helpcenter
  useEffect(() => {
    setSelectedLevel2(null);
    setSelectedLevel3Category(null);
  }, [level1category]);

  const items = [{ label: updateprojectname }];
  if (level1category) {
    items.push({ label: level1category.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) });
  }
  if (selectedLevel2) {
    items.push({ label: selectedLevel2 });
  }

  if (selectedLevel3Category) {
    items.push({ label: selectedLevel3Category });
  }
  const home = { icon: 'fa fa-home', template: <Link to="/"><i className="fa fa-home"></i></Link> };

  return (
    <>
      <Magamenu />
      <div className="container-fluid">
        <div className={`product-guide-container pb-0 mt-7`}>
          {/* {level1category !== 'introduction' && level1category !== 'installation-guide' && <div className="pdf-icon-container">
            <h6 className="helpcenterdownloadpdf m-0" onClick={handleDownloadPDF}><i className="fa-solid fa-file-pdf"></i></h6>
          </div>} */}
          <div className="row paddding-dropdown">
            <div className="col col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 categorySection">
              {apidata.length > 0 ? (
                <div className="lightmode projectnametitle d-flex justify-content-between">
                  <h2 className="guide-title third-h1-anim">
                    {updateprojectname}
                  </h2>
                </div>
              ) : (
                <div className="lightmode projectnametitle">
                  <Skeleton width="100%" height="40px" />
                </div>
              )}
              <div>
                {apidata.length > 0 && (
                  <div ref={inputRef} className="relative w-full mt-3">
                    <span className="p-input-icon-right w-full">
                      <i className="fa fa-search" />
                      <InputText placeholder="Search" value={searchQuery} onChange={handleSearchInputChange} className="p-inputtext-sm w-full" />
                    </span>
                    {suggestions.length > 0 && (
                      <ul className="suggestions absolute left-0 w-full">
                        {suggestions?.map((category, index) => (
                          <li key={index} onClick={() => handleSuggestionSelect(category)} className={`suggestion ${index === selectedSuggestionIndex ? 'selected' : ''} `}>
                            {category}
                          </li>
                        ))}
                      </ul>
                    )}
                    {searchQuery !== '' && suggestions.length === 0 && (
                      <div className="suggestions absolute left-0 w-full max-h-48 overflow-y-auto border border-gray-300 bg-white rounded shadow flex items-center justify-center">
                        No results found.
                      </div>
                    )}
                  </div>
                )}
              </div>
              {apidata.length > 0 ? (
                <>
                  <div className={windowWidth < 992 ? "flex justify-content-between align-items-center" : ''}>
                    <div>
                      <Dropdown
                        value={level1category ? convertTitleCase(level1category?.replace(/\-/g, ' ')) : level1category}
                        options={level1Categories}
                        onChange={handleCategoryChange}
                        placeholder="Select Category"
                        optionVisible={true}
                        ref={dropdownRef}
                        className="dropbtn-cust dropdown-cust drpheight"
                        panelClassName='panelCatdropdown'
                        itemTemplate={arrowOptionTemplate}
                      // title={level1category ? convertTitleCase(level1category?.replace(/\-/g, ' ')) : 'Select Category'}
                      />
                      {/* <Tooltip target=".drpheight" content={level1category ? convertTitleCase(level1category?.replace(/\-/g, ' ')) : level1category} position="bottom" mouseTrack mouseTrackTop={-20} className="tooltip-hidden z-5" /> */}
                    </div>
                    <div className="tab-content" id="user-guide-tab-container">
                      <div className="sidebar-guide-content">
                        <div className="mobile-toggle d-lg-none">
                          <button onClick={toggleSidenav} className="btn">
                            <i className="fa-solid fa-bars"></i>
                          </button>
                        </div>
                        <Sidenav className={windowWidth < 992 ? `sidenav ${isSidenavOpen ? 'open' : 'closed'}` : 'sidenav d-lg-block open'}>
                          <Sidenav.Body>
                            {isSidenavOpen && (
                              <button className="sidenav-close-btn" onClick={toggleSidenav}>
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                            <Nav>
                              {level2Categories?.map((item) =>
                                item.level3data ? (
                                  <div key={item.level2data}>
                                    <Nav.Item
                                      className="level2"
                                      onClick={() => {
                                        handleToggle(item.level2data);
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div className="d-flex justify-content-between">
                                        {item.level2data}
                                        <span style={{ marginRight: '8px' }}>{openItem === item.level2data ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}</span>
                                      </div>
                                    </Nav.Item>

                                    {openItem === item.level2data && (
                                      <div>
                                        {level3Categories?.filter((e) => e.level2Name === item.level2data)?.map((subItem) => (
                                          <Nav.Item
                                            key={subItem.category}
                                            onClick={() => {
                                              handleLevel3DropdownChange(subItem);
                                              if (isMobileScreen()) {
                                                toggleSidenav();
                                              }
                                            }}
                                            className={`level3 ${selectedLevel3Category === subItem.category ? "active" : ""}`}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            <div className="d-flex justify-content-between">
                                              {subItem.category}
                                              <span style={{ marginRight: '8px' }}>{selectedLevel3Category === subItem.category ? <i className="fas fa-link cursor-pointer" onClick={copyToClipboard}></i> : <i></i>}</span>
                                            </div>
                                          </Nav.Item>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <Nav.Item key={item.level2data} onClick={() => {
                                    handleLevel2CategoryClick(item);
                                    if (isMobileScreen()) {
                                      toggleSidenav();
                                    }
                                  }} className={selectedLevel2 === item.level2data ? "active" : ""} >
                                    <div className="d-flex justify-content-between">
                                      {item.level2data}
                                      <span style={{ marginRight: '8px' }}>{selectedLevel2 === item.level2data ? <i className="fas fa-link cursor-pointer" onClick={copyToClipboard}></i> : <i></i>}</span>
                                    </div>
                                  </Nav.Item>
                                )
                              )}
                            </Nav>
                          </Sidenav.Body>
                        </Sidenav>
                      </div>
                      {windowWidth > 992 && < div className="faq-button-container m-2 p-1 py-2 ">
                        <Link to="/faqs">
                          <p className='m-0 pl-6'>FAQs</p>
                        </Link>
                      </div>}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Skeleton width="100%" height="40px" className="mt-2" />
                  <Skeleton width="100%" height="250px" className="mt-3" />
                </>
              )}
            </div>

            <div className="col col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9 descriptionSection">
              <div>
                {apidata.length > 0 ? (
                  <div className="product-guide-inner-container" ref={descriptionContainerRef}>
                    <div className="light-mode-title" id="description-container">
                      <DeferredContent>
                        <BreadCrumb model={items} home={home} />
                        <div className="light-mode-title help-para" onClick={handleImageClick} dangerouslySetInnerHTML={{ __html: description }}></div>
                      </DeferredContent>
                      <Galleria
                        ref={galleria}
                        value={imgUrls}
                        onItemChange={onItemChange}
                        activeIndex={selectedIndex}
                        numVisible={imgUrls?.length}
                        style={{ maxWidth: '100VW', maxHeight: '100%' }}
                        circular
                        fullScreen
                        showItemNavigators
                        showThumbnails={false}
                        item={itemTemplate}
                        footer={footer}
                        thumbnail={thumbnailTemplate}
                      />
                    </div>
                    <ScrollTop target="parent" threshold={100} className="w-3rem h-3rem descriptiontopscroll ml-auto " icon="fa fa-arrow-up text-base" />
                  </div>
                ) : (
                  <Skeleton width="95%" height="600px" />
                )}
              </div>
              <div>
                <div className="next-button-container">
                  <div className="nextprevbtn">
                    <Button onClick={handlePreviousCategory} disabled={currentIndex === 0}><i class="fa-solid fa-chevron-left"></i></Button>
                  </div>

                  <div className="nextprevbtn">
                    <Button onClick={handleNextCategory} disabled={currentIndex === level2Categories.length - 1}><i class="fa-solid fa-chevron-right"></i></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      {showCopyMessage && (
        <div className="copy-message">Copied URL</div>
      )
      }
      <Footer />
    </>
  );
}